@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&family=Caveat&family=Nunito:wght@200&family=Quicksand:wght@300;500&family=Roboto:wght@100&display=swap');

* {
    --quicksand: 'Quicksand';
    --caveat: 'Caveat';
    --nunito: 'Nunito';
    --blue: rgb(0, 0, 134);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-width: 0;
}

html,
body {
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.46667rem;
    scroll-behavior: smooth;
}

/* Skills and Tools */
#Skillsheader {
    color: #3C3EF1;
    font-family: var(--quicksand);
    font-size: 4vw;
    font-weight: 600;
    background-color: transparent;
    text-align: center;
    margin-bottom: 1vh;
}

#table {
    display: flex;
    width: 80vw;
    flex-direction: column;
    align-items: flex-start;
}

#table>p {
    align-self: center;
}

tr {
    display: flex;
    align-items: center;
    align-self: stretch;
}

td:first-child {
    display: flex;
    width: 20vw;
    padding: 0 1.2vw;
    align-items: center;
    align-self: stretch;
    background: #3C3EF1;
    color: #FFF;
    font-family: var(--quicksand);
    font-size: 2.5vw;
    font-weight: 600;
}

tr:first-child td:first-child {
    border-radius: 5px 0 0 0px;
}

tr:first-child td:nth-child(2) {
    border-radius: 0 5px 0 0;
}

tr:nth-child(5) td:first-child {
    border-radius: 0 0 0 5px;
}

tr:nth-child(5) td:nth-child(2) {
    border-radius: 0 0 5px 0;
}

td:nth-child(2) {
    display: flex;
    padding: 3vh 1.2vw;
    height: max-content;
    align-items: center;
    flex: 1 0 0;
    background: #F7F7F7;
    color: #33404E;
    font-family: var(--nunito);
    font-size: 2.5vw;
    font-weight: 600;
    line-height: 170%;
}

@media screen and (max-width: 1199.9px) and (orientation: landscape) {
    #Skillsheader {
        font-size: 2.5vw;
    }

    td:first-child {
        font-size: 2vw;
    }

    td:nth-child(2) {
        font-size: 1.5vw;
    }
}

@media (min-width: 1200px) {

    #Skillsheader {
        font-size: 3vw;
    }

    #table {
        width: 71vw;
    }

    td:first-child {
        font-size: 1.5vw;
    }

    td:nth-child(2) {
        padding: 3.8vh 1.2vw;
        font-size: 1.2vw;
    }
}