@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&family=Caveat&family=Nunito:wght@200&family=Quicksand:wght@300;500&family=Roboto:wght@100&display=swap');

* {
    --quicksand: 'Quicksand';
    --caveat: 'Caveat';
    --nunito: 'Nunito';
    --blue: rgb(0, 0, 134);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-width: 0;
}

html,
body {
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.46667rem;
    scroll-behavior: smooth;
}

/* home */
main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

#welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7vh;
}

#intro {
    color: #3C3EF1;
    font-family: var(--quicksand);
    font-size: 6.5vw;
    font-weight: 500;
}

#hi {
    color: #33404E;
    font-family: var(--quicksand);
    font-size: 3.5vw;
    font-weight: 600;
}

#certs {
    display: flex;
    position: fixed;
    gap: 0.5vw;
    z-index: -100;
    top: 30%;
    opacity: 0.2;
}

#certs>div {
    display: flex;
    border-radius: 0.4rem;
    border: 1px solid #E7E7E7;
    background: #F9F9F9;
    justify-content: center;
    align-items: center;
}

#certs>div>a {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: var(--nunito);
    text-decoration: none;
    padding: 0.5rem 0.1rem;
    font-size: 0.5vw;
    align-items: center;
}

#certs>div>a>img {
    width: 150px;
    height: 150px;
}

#talk {
    width: 65vw;
    color: #33404E;
    text-align: center;
    font-family: var(--nunito);
    font-size: 2.8vw;
    font-weight: 600;
    margin: 1.2vh 0;
}

#logos {
    display: flex;
    width: 70vw;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 0.5vw;
    flex-wrap: wrap;
}

images {
    width: 65px;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.4rem;
    border: 1px solid #E7E7E7;
    background: #F9F9F9;
}

#aws {
    width: 35px;
    height: 25px;
}

.htmlcss {
    width: 20px;
    height: 25px;
}

.flaskgit {
    width: 45px;
    height: 20px;
}

images img {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    background-color: lightgray 50% / cover no-repeat;
    visibility: hidden;
    animation: show 2s linear forwards;
    animation-delay: calc(0.2s * var(--i));
}

@keyframes show {
    0% {
        visibility: visible;
        scale: 0.5;
    }

    25% {
        transform: translateY(-1vh);
    }

    50% {
        transform: translateY(1vh);
    }

    75% {
        scale: 1.2;
    }

    100% {
        visibility: visible;
        scale: 1.0;
    }
}

@media screen and (max-width: 1199.9px) and (orientation: landscape) {

    #intro {
        font-size: 6vh;
    }

    #hi {
        font-size: 3.5vh;
    }

    #talk {
        font-size: 3vh;
    }
}

/* PC Screens */
@media screen and (min-width: 1200px) {

    /* home */
    #welcome {
        gap: 1.6vh;
    }

    #intro {
        font-size: 6.5vw;
    }

    #hi {
        font-size: 2vw;
    }

    #talk {
        font-size: 1.1vw;
    }

    #logos {
        width: 50vw;
    }
}