@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&family=Caveat&family=Nunito:wght@200&family=Quicksand:wght@300;500&family=Roboto:wght@100&display=swap');

* {
    --quicksand: 'Quicksand';
    --caveat: 'Caveat';
    --nunito: 'Nunito';
    --blue: rgb(0, 0, 134);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-width: 0;
}

html,
body {
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.46667rem;
    scroll-behavior: smooth;
}

/* DevOps and WebDev Styling */
#title {
    color: #3C3EF1;
    font-family: var(--quicksand);
    font-size: 4vw;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1vh;
}

section {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 100vh;
    width: 90vw;
}

section>p {
    width: 100%;
}

section>a {
    display: flex;
    width: 30vw;
    height: 12vh;
    padding: 0.5vw;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #33404E;
    font-family: var(--nunito);
    font-size: 2vw;
    font-weight: 600;
}

section>a>img {
    width: 100%;
    height: 100%;
    align-self: center;
    border-radius: 0.26667rem;
    background-color: lightgray 50% / cover no-repeat, #EEE;
}

#cloud>section>a>p,
#webdev>section>a>p,
#writing>section>a>p {
    position: relative;
    top: -3vh;
    width: 100%;
    background-color: white;
    padding: 0.4vh 0.8vh;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @supports (-webkit-line-clamp: 1) {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

#cloud>section>a>p:hover,
#webdev>section>a>p:hover {
    display: block;
    overflow: visible;
    height: 60%;
}

section>a:hover {
    transform: translateY(-0.3rem);
}

/* footer */
#footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
    font: 600 2.5vw var(--quicksand);
    border-top: 2px solid #33404E;
    color: #33404E;
}

@media screen and (min-width:470px) and (max-width: 1024.9px) {

    section>a {
        height: 17vh;
    }

    #webdev>section>a>p,
    #cloud>section>a>p,
    #writing>section>a>p {
        font-size: 1.8vw;
    }
}

@media screen and (max-width: 1199.9px) and (orientation: landscape) {
    #title {
        font-size: 2.5vw;
    }

    section>a {
        width: 16vw;
        height: 25vh;
    }

    section>a>img {
        height: 75%;
    }

    #footer {
        font-size: 3vh;
    }

    #webdev>section>a>p,
    #cloud>section>a>p,
    #writing>section>a>p {
        top: -6vh;
        font-size: 1.3vw;
    }
}

@media screen and (min-width: 1200px) {
    #title {
        font-size: 2.5vw;
    }

    section {
        width: 85%;
    }

    section>a {
        width: 15vw;
        height: 22vh;
    }

    #webdev>section>a>p,
    #cloud>section>a>p,
    #writing>section>a>p {
        top: -25%;
        font-size: 0.9vw;
    }

    #footer {
        font-size: 1vw;
        height: 1vh;
    }
}