@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&family=Caveat&family=Nunito:wght@200&family=Quicksand:wght@300;500&family=Roboto:wght@100&display=swap');

* {
    --quicksand: 'Quicksand';
    --caveat: 'Caveat';
    --nunito: 'Nunito';
    --blue: rgb(0, 0, 134);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-width: 0;
}

html,
body {
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.46667rem;
    scroll-behavior: smooth;
}

/* nav */
nav {
    display: flex;
    position: fixed;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 3px;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: transparent;
}

#logo {
    position: fixed;
    left: 5px;
}

#logo>svg {
    width: 5vw;
}

.active>svg>path {
    fill: #3C3EF1;
}

.active>#codeblock>path {
    stroke: #3C3EF1;
}

links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5vw;
}

links a {
    font: 900 10px var(--quicksand);
    color: #3C3EF1;
    text-align: center;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#codeblock path {
    fill: none;
}

a svg {
    width: 5vw;
    height: 5vh;
}

span {
    display: none;
}

nav a:hover {
    scale: 1.1;
}

/* background */
#background {
    display: block;
    z-index: -100;
    position: fixed;
    right: 0;
    animation: translate 8s ease-in-out -2s infinite;
}


@keyframes translate {
    0% {
        transform: translateY(-3%);
    }

    50% {
        transform: translateY(3%);
    }

    100% {
        transform: translateY(-3%);
    }
}

@media screen and (max-width: 1199.9px) and (orientation: landscape) {

    /* nav */
    links a {
        font-size: 1.5vw;
    }

    a svg {
        height: 3vw;
    }

    /* background */
    #background {
        scale: 1.2;
        top: 30vh;
    }
}

@media screen and (min-width: 1200px) {

    /* nav */
    nav {
        flex-direction: column;
        width: 5vw;
        height: 100vh;
        justify-content: flex-end;
        align-items: center;
        background: #3C3EF1;
    }

    #logo {
        position: fixed;
        top: 1.5vh;
        left: 0;
    }

    #logo>svg>path {
        fill: white;
    }

    .active>svg>path {
        fill: white;
    }

    .active>#codeblock>path {
        stroke: white;
    }

    links {
        display: flex;
        flex-direction: column;
        transform: translateY(-20vh);
        gap: 3.5vh;
    }

    links a {
        font-size: 1.5vh;
    }

    a span {
        color: white;
        display: block;
    }

    a svg {
        width: 5vw;
        height: 4vh;
    }

    a>svg>path {
        fill: #B1B2F9;
    }

    #codeblock path {
        stroke: #B1B2F9;
    }

    /* background */
    #background {
        scale: 1.5;
        right: -10vh;
        top: 30vh;
    }
}